export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  branch: {
    label: 'Modulo',
    type: String,
    options: [{
      id: 'devices',
      name: 'Dispositivos GPS'
    }, {
      id: 'cameras',
      name: 'Camaras de Video Vigilancia (PMI)'
    }, {
      id: 'lpr',
      name: 'Lectores de Placa (LPR)'
    }, {
      id: 'bus',
      name: 'Transporte Urbano'
    }, {
      id: 'incidents',
      name: 'Incidencia 911 (cad)'
    }, {
      id: 'sensor',
      name: 'Botones de panico'
    }]
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    autocomplete: false
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true
  }
};
